import React from 'react'
import { graphql } from 'gatsby'
import clsx from 'clsx'

import Seo from '@components/seo/Index'
import LayoutInnerPage from '@src/layouts/InnerPage'
import BrokerageWebsitesHero from '@components/pages/brokerage-websites/Hero'
import BrokerageWebsitesListings from '@components/pages/brokerage-websites/Listings'
import LeadContactForm from '@components/global/forms/LeadContactForm/Index'
import ReviewsServiceSetsUsApart from '@components/global/Reviews/ServiceSetsUsApart'
import ProductComparison from '@components/global/product/Comparison'

import * as cx from './BrokerageWebsites.module.scss'

const BrokerageWebsites = ({ data: { page }, pageContext: { seo, uri } }) => {
  const banner = page?.bannerForm
  const brokerageWebsites = page?.brokerageWebsites
  const leadContactForm = page?.leadContactForm

  const primaryColor = {
    'berkshire-hathaway': '#571A51',
    'century-21': '#BEAF87',
    compass: '#2E2E2E',
    'coldwell-banker': '#14377D',
    'douglas-elliman': '#009CBD',
    'keller-williams': '#C33641',
    ReMax: '#003DA5',
    sothebys: '#00275E',
  }

  const gradient = {
    'berkshire-hathaway':
      'linear-gradient(124.13deg, #55194F -2.95%, #621E5B 87.17%)',
    'century-21': 'linear-gradient(124.13deg, #BBAA7B -2.95%, #BEAF87 87.17%)',
    compass: 'linear-gradient(124.13deg, #151515 -2.95%, #2F2F2F 87.17%)',
    'coldwell-banker':
      'linear-gradient(124.13deg, #123375 -2.95%, #14377D 87.17%)',
    'douglas-elliman': 'linear-gradient(180deg, #009CBD 0%, #0DAED0 100%)',
    'keller-williams':
      'linear-gradient(124.13deg, #C33641 -2.95%, #C33641 87.17%)',
    ReMax: 'linear-gradient(124.13deg, #003DA5 -2.95%, #003692 87.17%)',
    sothebys: 'linear-gradient(124.13deg, #00275E -2.95%, #002150 87.17%)',
  }

  return (
    <LayoutInnerPage>
      <Seo title={page.title} seo={seo} uri={uri} />
      <BrokerageWebsitesHero
        wrapperClassName={clsx(
          cx.hero,
          brokerageWebsites?.logo?.gradient === 'keller-williams' &&
            cx.kellerWilliamsHero
        )}
        breadcrumbs={page?.seo?.breadcrumbs}
        brokerName={brokerageWebsites?.logo?.gradient}
        logoSrc={brokerageWebsites?.logo?.long}
        monoLogoSrc={brokerageWebsites?.logo?.mono}
        title={page.title}
        laptop={banner?.laptop?.url}
        tablet={banner?.tablet?.url}
        mobile={banner?.mobile?.url}
        primaryColor={primaryColor[brokerageWebsites?.logo?.gradient]}
        gradient={primaryColor[brokerageWebsites?.logo?.gradient]}
      />
      <BrokerageWebsitesListings
        wrapperClassName={clsx(
          brokerageWebsites?.logo?.gradient === 'compass' && cx.compassListings,
          brokerageWebsites?.logo?.gradient === 'coldwell-banker' &&
            cx.coldwellBankerListings
        )}
        title={brokerageWebsites?.gallery?.title}
        description={brokerageWebsites?.gallery?.description}
        items={brokerageWebsites?.gallery?.items}
        primaryColor={primaryColor[brokerageWebsites?.logo?.gradient]}
        monoLogoSrc={
          brokerageWebsites?.logo?.gallery?.url
            ? brokerageWebsites?.logo?.gallery
            : brokerageWebsites?.logo?.mono
        }
      />
      <LeadContactForm
        version={leadContactForm?.version}
        background={leadContactForm?.background}
        modelOrBackground={leadContactForm?.model}
        title={leadContactForm?.title}
        subtitle={leadContactForm?.subtitle}
        submitLabel={leadContactForm?.submit}
        leadName={page?.title}
        layout="B"
      />
      <ReviewsServiceSetsUsApart
        backgroundUrl="https://resources.agentimage.com/redesign/local/brokerage-success-stories.jpg"
        title="Success Stories"
        titleClassName="heading-3"
        subtitle="Agent Image is proud to create amazing customer experiences. Here are just a few of our loyal customers who are happy to share how their Agent Image website has helped them achieve success."
        subtitleClassName="default-body large"
        displayStars={false}
      />
      <ProductComparison
        title="Looking For Something More Custom For Your Real Estate Company?"
        titleClassName="heading-3"
        subtitle="These Agent Image custom websites showcase your company’s unique brand and can help drive online traffic."
        subtitleClassName="default-body large"
        removeProductName="agentimagex"
      />
      <div className={clsx(cx.disclaimer)}>
        <p>{brokerageWebsites?.disclaimer}</p>
      </div>
    </LayoutInnerPage>
  )
}

export default BrokerageWebsites

export const pageQuery = graphql`
  query BrokerageWebsitesPageById($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "MMMM DD, YYYY")
      seo {
        breadcrumbs {
          text
          url
        }
      }

      bannerForm {
        title
        description
        laptop {
          url
        }
        tablet {
          url
        }
        mobile {
          url
        }
        formTitle
        formSubtitle
        formSubmitLabel
      }

      brokerageWebsites {
        logo {
          gradient
          mono {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
          long {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
          gallery {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
        }
        gallery {
          title
          description
          items {
            name
            location
            description
            button_label
            tablet {
              url
            }
            mobile {
              url
            }
          }
        }
        disclaimer
      }

      leadContactForm {
        background {
          url
          srcset
          sizes
          width
          height
        }
        version
        model
        title
        subtitle
        submit
      }
    }
  }
`
