import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Link } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import {
  useHomePageStaticData,
  QuizComparisonItems,
} from '@hooks/home-page-static'
import { removeElementTags } from '@utils/removeElementTags'
import parse from 'html-react-parser'

import ElementImageFile from '@components/global/element/ImageFile'
import CheckItems from '@components/global/CheckItems'

import * as cx from './ProductComparison.module.scss'

const ProductComparison = ({
  wrapperClassName,
  headingClassName,
  title = 'Find the best match',
  titleClassName = 'subtitle-1',
  subtitle = 'Compare Websites',
  subtitleClassName = 'heading-2',
  removeProductName = '',
}) => {
  const data = useHomePageStaticData()

  return (
    <section className={clsx(cx.comparison, wrapperClassName)}>
      <div className={clsx(cx.heading, headingClassName)}>
        <h2 className={clsx(titleClassName)}>{parse(title)}</h2>
        <h3 className={clsx(subtitleClassName)}>{parse(subtitle)}</h3>
      </div>
      <div className={clsx(cx.items)}>
        {QuizComparisonItems.map((item, index) => {
          if (removeProductName !== item.id) {
            return (
              <div key={item.id} className={clsx(cx.item)}>
                <ElementImageFile
                  src={getImage(data[item.id])}
                  alt={removeElementTags(item.title)}
                />
                <div className={clsx(cx.content)}>
                  <div className={clsx(cx.title, 'subtitle-9')}>
                    {parse(item.title)}
                  </div>
                  <div className={clsx(cx.list)}>
                    <CheckItems
                      items={item.items}
                      parentClass={cx.checklist}
                      hasLink={true}
                      buttonClass={`primary-button`}
                      buttonText={item.button.label}
                      buttonUrl={item.button.url}
                    />
                  </div>
                </div>
              </div>
            )
          }
        })}
      </div>
      <div>
        <Link to="#" className={`primary-button alt large`}>
          Click to view comparison chart
        </Link>
      </div>
    </section>
  )
}

ProductComparison.propTypes = {
  wrapperClassName: PropTypes.string,
  headingClassName: PropTypes.string,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  subtitle: PropTypes.string,
  subtitleClassName: PropTypes.string,
  removeProductName: PropTypes.string,
}

export default ProductComparison
