import React from 'react'
import clsx from 'clsx'

const SvgPinOutline = ({ className = '' }) => {
  return (
    <svg
      role="img"
      width="14"
      height="20"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 20"
      style={{ enableBackground: 'new 0 0 14 20' }}
      className={clsx('fill-current', className)}
      fill="none"
    >
      <title>Pin Outline</title>
      <path
        d="M0.721628 10.2475L0.72186 10.2481C0.899107 10.6688 1.24699 11.3188 1.49907 11.7017L1.49913 11.7018L6.05815 18.6095C6.26 18.9159 6.56527 19.1 6.89928 19.1C7.23327 19.1 7.53852 18.9159 7.74037 18.61C7.74037 18.61 7.74038 18.61 7.74038 18.61L12.2998 11.7018L12.2998 11.7017C12.5524 11.3188 12.8999 10.6688 13.0771 10.2481L13.0772 10.2478L13.0772 10.2477C13.1001 10.193 13.6486 8.87853 13.6486 7.64928C13.6486 3.92756 10.621 0.9 6.89928 0.9C3.17756 0.9 0.15 3.92756 0.15 7.64928C0.15 8.8694 0.691139 10.174 0.721055 10.2461L0.721628 10.2475ZM12.1719 9.86597L12.1715 9.86705C12.0179 10.2332 11.6997 10.8273 11.4797 11.161C11.4797 11.161 11.4797 11.161 11.4797 11.161L6.92025 18.0692L6.92022 18.0692C6.91254 18.0809 6.90568 18.0909 6.89948 18.0996C6.89328 18.0909 6.88642 18.0809 6.87874 18.0692L6.8787 18.0692L2.31927 11.161C2.31927 11.161 2.31926 11.161 2.31925 11.161C2.09925 10.8273 1.78109 10.2328 1.62748 9.86666L1.53527 9.90534L1.62764 9.86704C1.62064 9.85015 1.13227 8.67215 1.13227 7.64928C1.13227 4.46944 3.71944 1.88227 6.89928 1.88227C10.0791 1.88227 12.6663 4.46944 12.6663 7.64928C12.6663 8.15887 12.5448 8.7096 12.4208 9.1378C12.3591 9.35117 12.2971 9.53259 12.2504 9.66125C12.227 9.72556 12.2075 9.77662 12.1936 9.81183C12.1805 9.84528 12.1729 9.86376 12.172 9.86579L12.1719 9.86597Z"
        strokeWidth="0.2"
      />
      <path
        d="M6.89911 4.02939C4.90268 4.02939 3.27891 5.65357 3.27891 7.6496C3.27891 9.64563 4.90268 11.2698 6.89911 11.2698C8.89555 11.2698 10.5193 9.64563 10.5193 7.6496C10.5193 5.65357 8.89555 4.02939 6.89911 4.02939ZM6.89911 10.2875C5.44495 10.2875 4.26117 9.10414 4.26117 7.6496C4.26117 6.19506 5.44495 5.01166 6.89911 5.01166C8.35328 5.01166 9.53706 6.19506 9.53706 7.6496C9.53706 9.10414 8.35328 10.2875 6.89911 10.2875Z"
        strokeWidth="0.2"
      />
    </svg>
  )
}

export default SvgPinOutline
