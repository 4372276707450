import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import clsx from 'clsx'
import { removeElementTags } from '@utils/removeElementTags'
import { modifyImageKeys } from '@utils/modifyImageKeys'
import useMedia, { media } from '@hooks/useMedia'

import ElementImageFile from '@components/global/element/ImageFile'

import Breadcrumbs from '@components/seo/Breadcrumbs'
import FormFreeConsultation from '@components/global/forms/FreeConsultation'

import DeviceLaptop from '@components/global/devices/Laptop'
import DeviceTablet from '@components/global/devices/Tablet'
import DeviceMobile from '@components/global/devices/Mobile'

import * as cx from './Hero.module.scss'

const BrokerageWebsitesHero = ({
  wrapperClassName,
  breadcrumbs,
  logoSrc,
  monoLogoSrc,
  title = '10 Best Berkshire Hathaway Brokerage Websites',
  laptop,
  tablet,
  mobile,
  primaryColor,
  gradient,
}) => {
  const mediaTablet = useMedia(media.tablet)
  const [isTablet, setIsTablet] = useState(false)

  useEffect(() => {
    setIsTablet(mediaTablet)
  }, [mediaTablet])

  const logoBar = (
    <div className={clsx(cx.bar)}>
      <div className={clsx(cx.logo)} style={{ borderColor: primaryColor }}>
        <ElementImageFile
          src={modifyImageKeys(logoSrc)}
          alt={removeElementTags(title)}
          lazy={false}
        />
      </div>
      <div className={clsx(cx.details)} style={{ background: gradient }}>
        <ElementImageFile
          src={modifyImageKeys(monoLogoSrc)}
          alt={removeElementTags(title)}
          lazy={false}
          className={clsx(cx.mono)}
        />
        <h2 className="subtitle-2">Brokerage Websites</h2>
        <p className="menu-link">
          Cutting-edge websites for innovative agents.
        </p>
      </div>
    </div>
  )

  return (
    <section
      id="brokerage-websites-hero"
      className={clsx(cx.wrapper, wrapperClassName)}
    >
      <div className={clsx(cx.container)}>
        <div className={clsx(cx.breadcrumbs)}>
          <Breadcrumbs
            wrapperClassName="global-breadcrumbs"
            data={breadcrumbs}
          />
        </div>
        <h1 className="hidden">{title}</h1>
        {isTablet && logoBar}
        <div className={clsx(cx.content)}>
          <div className={clsx(cx.banner)}>
            <div
              className={clsx(cx.gradientBg, 'bg-gradient-slider-300')}
            ></div>
            {!isTablet && logoBar}
            <div className={clsx(cx.device)}>
              <canvas
                width={794}
                height={406}
                className={clsx(cx.placeholder, 'desktop-only')}
              ></canvas>
              <canvas
                width={368}
                height={361}
                className={clsx(cx.placeholder, 'mobile-only')}
              ></canvas>
              <DeviceLaptop
                imagePlaceholder="https://resources.agentimage.com/redesign/local/laptop-old.png"
                imageClassName={clsx(cx.laptopImage)}
                placeholderWidth={1108}
                placeholderHeight={681}
                width={1108}
                height={681}
                className={clsx(cx.laptop)}
                imageSrc={laptop}
                lazy={false}
              />
              <DeviceTablet
                className={clsx(cx.tablet)}
                imageSrc={tablet}
                lazy={false}
              />
              <DeviceMobile className={clsx(cx.mobile)} imageSrc={mobile} />
            </div>
          </div>
          <div className={clsx(cx.form)}>
            <div className="banner-form-heading">
              <h2>
                <span className="subtitle-6">Get In Touch With Us For A</span>
                <span className="subtitle-2">Free Consultation</span>
              </h2>
            </div>
            <div className="banner-form-line"></div>
            <FormFreeConsultation
              submitLabel="I want a stunning website!"
              isLabelHidden={true}
              buttonClassName="primary-button large"
              wrapperClass={clsx('banner-form')}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

BrokerageWebsitesHero.propTypes = {
  wrapperClassName: PropTypes.string,
  breadcrumbs: PropTypes.array.isRequired,
  logoSrc: PropTypes.object,
  monoLogoSrc: PropTypes.object,
  title: PropTypes.string,
  laptop: PropTypes.string,
  tablet: PropTypes.string,
  mobile: PropTypes.string,
  primaryColor: PropTypes.string,
  gradient: PropTypes.string,
}

export default BrokerageWebsitesHero
