import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import clsx from 'clsx'
import { removeElementTags } from '@utils/removeElementTags'
import { modifyImageKeys } from '@utils/modifyImageKeys'

import ElementImageFile from '@components/global/element/ImageFile'
import DeviceTablet from '@components/global/devices/Tablet'
import DeviceMobile from '@components/global/devices/Mobile'

import ModalForm from '@components/global/modal/Form'
import FormSignUp from '@components/global/forms/SignUp'

import * as cx from './Listings.module.scss'

import SvgPinOutline from '@svg/pin-outline.inline'

const BrokerageWebsitesListings = ({
  wrapperClassName,
  title,
  description,
  items,
  primaryColor,
  monoLogoSrc,
}) => {
  const modalRef = useRef()
  const [leadName, setLeadName] = useState('')

  return (
    <section
      id="brokerage-websites-listings"
      className={clsx(cx.wrapper, wrapperClassName)}
    >
      <ModalForm
        childFunc={modalRef}
        title="Sign Up Today For A <br>Free Consultation"
        description="See how Agent Image can help you grow your real estate business."
        template="m1"
        type="a"
      >
        <FormSignUp
          submitLabel="Get started today"
          leadName={`Gallery: ${removeElementTags(leadName)}`}
        />
      </ModalForm>
      <div className={clsx(cx.container)}>
        <div className={clsx(cx.heading)}>
          <h2 className="heading-5">{parse(title)}</h2>
          <p className="default-body large">{parse(description)}</p>
        </div>
        {items.map((item, index) => {
          return (
            <div key={index} className={clsx(cx.item)}>
              <div className={clsx(cx.device)}>
                <canvas
                  width={528}
                  height={403}
                  className={clsx(cx.placeholder)}
                ></canvas>
                <canvas
                  width={332}
                  height={306}
                  className={clsx(cx.primaryColor)}
                  style={{ backgroundColor: primaryColor }}
                ></canvas>
                <div className={clsx(cx.mono)}>
                  <ElementImageFile
                    src={modifyImageKeys(monoLogoSrc)}
                    alt={removeElementTags(title)}
                    lazy={true}
                  />
                </div>
                <DeviceTablet
                  className={clsx(cx.tablet)}
                  imageSrc={item?.tablet?.url}
                  lazy={true}
                />
                <DeviceMobile
                  className={clsx(cx.mobile)}
                  imageSrc={item?.mobile?.url}
                  lazy={true}
                />
              </div>
              <div className={clsx(cx.details)}>
                <h3 className={clsx('subtitle-2', cx.title)}>{item.name}</h3>
                <div className={clsx(cx.location)}>
                  <SvgPinOutline />
                  <span className="subtitle-5">{item.location}</span>
                </div>
                <p className={clsx('default-body', cx.description)}>
                  {item.description}
                </p>
                <button
                  type="button"
                  className="primary-button"
                  onClick={(e) => {
                    setLeadName(item.name)
                    modalRef.current.openModal()
                  }}
                >
                  {item.button_label
                    ? item.button_label
                    : 'Get Pricing & Features'}
                </button>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}

BrokerageWebsitesListings.propTypes = {
  wrapperClassName: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  items: PropTypes.array,
  primaryColor: PropTypes.string,
  monoLogoSrc: PropTypes.object,
}

export default BrokerageWebsitesListings
