// extracted by mini-css-extract-plugin
export var banner = "Hero-module--banner--a6062";
export var bar = "Hero-module--bar--d1825";
export var breadcrumbs = "Hero-module--breadcrumbs--95df1";
export var container = "Hero-module--container--68258";
export var content = "Hero-module--content--64f2c";
export var details = "Hero-module--details--6db33";
export var device = "Hero-module--device--da55b";
export var form = "Hero-module--form--d118d";
export var gradientBg = "Hero-module--gradient-bg--dee55";
export var laptop = "Hero-module--laptop--d4759";
export var laptopImage = "Hero-module--laptopImage--6241e";
export var logo = "Hero-module--logo--7dfae";
export var mobile = "Hero-module--mobile--04c5c";
export var mono = "Hero-module--mono--ca499";
export var placeholder = "Hero-module--placeholder--7cc2b";
export var tablet = "Hero-module--tablet--a2118";
export var wrapper = "Hero-module--wrapper--3b188";