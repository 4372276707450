// extracted by mini-css-extract-plugin
export var container = "Listings-module--container--939f8";
export var description = "Listings-module--description--b01dc";
export var details = "Listings-module--details--9fe6f";
export var device = "Listings-module--device--e02e2";
export var heading = "Listings-module--heading--bdf90";
export var item = "Listings-module--item--8d284";
export var location = "Listings-module--location--07e0e";
export var mobile = "Listings-module--mobile--07277";
export var mono = "Listings-module--mono--48d4a";
export var placeholder = "Listings-module--placeholder--6ab7b";
export var primaryColor = "Listings-module--primary-color--e0723";
export var tablet = "Listings-module--tablet--a02fd";
export var title = "Listings-module--title--27b11";
export var wrapper = "Listings-module--wrapper--e0f67";